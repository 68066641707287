<template>
  <footer class="footer">
    <div class="footer-caption">
      <div class="footer-info">
        <p class="footer__text">Общество с ограниченной ответственностью «Код 93»
          <br>
          ИНН: 7430036788, ОГРН: 1227400006635
        </p>

      </div>
      <div class="footer-info">
        <p class="footer__text">Адрес организации: 456671, Челябинская область, Красноармейский р-н, п. Петровский,
          Учительская ул., д. 14, ком. 1 <br>
          Контактное лицо: Кривченко Анна, +79170284454, эл. почта – tsip.rf@mail.ru </p>
      </div>
    </div>
    <p class="footer__links">
      <a href="/docs/ofer.pdf" target="_blank" class="footer__text footer__links-element">ДОГОВОР ОФЕРТА </a>
      <a href="/docs/politica_obrabotki_personalnih_dannih.pdf" target="_blank"
        class="footer__text footer__links-element"> ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</a>
      <a href="/docs/polzovatelskoe_soglashenie.pdf" target="_blank"
        class="footer__text footer__links-element">ПОЛЬЗОВАТЕЛЬСКОЕ
        СОГЛАШЕНИЕ
      </a>
      <a href="/docs/soglashenie_na_obrabotky_personalnih_dannih.pdf" target="_blank"
        class="footer__text footer__links-element">СОГЛАСИЕ
        НА ОБРАБОТКУ
        ПЕРСОНАЛЬНЫХ ДАННЫХ
      </a>
      <a href="/docs/soglashenie_na_obrabotky_personalnih_dannih_nesovershennoletnih.pdf" target="_blank"
        class="footer__text footer__links-element">СОГЛАСИЕ НА ОБРАБОТКУ
        ПЕРСОНАЛЬНЫХ ДАННЫХ НЕСОВЕРШЕННОЛЕТНИХ
      </a>
    </p>
    <div class="footer-companies">
      <a href="https://profstories.ru/" target="_blank">
        <img src="@/assets/imgs/companies/profstor.png" alt="" class="footer-companies__element profstor" />
      </a>
      <a href="https://засобой.рф/" target="_blank">
        <img src="@/assets/imgs/companies/za.png" alt="" class="footer-companies__element za" />
      </a>
      <img v-if="false" src="@/assets/imgs/companies/work.png" alt="" class="footer-companies__element work" />
    </div>
  </footer>
</template>

<script>
import { appConfig } from "@/lib/appConfig";

export default {
  name: "PageFooter",
  computed: {
    appConfig() {
      return appConfig
    }
  },
};
</script>

<style scoped>
.footer {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3;
  margin-top: 4.5rem;
  margin-bottom: 7.5rem;
}

.footer-caption {
  display: flex;
  gap: 1.25rem;
  justify-content: space-between;
}

.footer-info {
  max-width: 100%;
  width: 100%;
}

.footer__links {
  margin-top: 1rem;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  max-width: 100%;
  width: 100%;
}

.footer__links-element {
  text-decoration: underline;
}

.footer__text {
  line-height: 1.3;
}

.footer-companies {
  margin-top: 2.313rem;
  padding-top: 2.313rem;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;
  justify-content: space-between;
}

.profstor {
  width: 6.66444rem;
}

.za {
  width: 15.3125rem;
}

.min {
  width: 16.6875rem;
}

.work {
  width: 12rem;
}

@media (max-width: 850px) {
  .footer {
    font-size: 0.625rem;
    margin-top: 2.5rem;
    margin-bottom: 2.31rem;
  }

  .footer-caption {
    max-width: 100%;
    gap: 1.06rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-info {
    order: 2;
  }


  .footer__text {
    line-height: 1.3;
  }


  .footer-companies {
    margin-top: 2.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.63rem;
  }

  .footer__links {
    margin-top: 1.5rem;
  }

  .profstor {
    order: 3;
    width: 4.92988rem;
  }

  .za {
    order: 1;
    width: 8.5625rem;
  }

  .min {
    width: 8.1875rem;
    height: 2.0625rem;
    order: 2;
  }

  .work {
    width: 6.6875rem;
    height: 2.5625rem;
    order: 4;
    margin-right: 1rem;
  }
}
</style>
