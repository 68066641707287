<script>
import "../../public/style/payment.css"

export default {
  name: "PaymentEndPageGood",
}
</script>

<template>
  <div class="wrapper ">
    <div class="filling">
      <div class="caption">
        <div class="caption-content">
          <p class="title">Спасибо! :)</p>
          <p class="text">Оплата прошла успешно, а чек с оплатой будет выслан вам на почту!</p>
        </div>
        <img src="@/assets/imgs/landing/questions-robot-mobile.png" alt="" class="robot">
      </div>
      <router-link :to="{ name: 'registration' }" class="info info__second">НАЧАТЬ
      </router-link>
    </div>
  </div>
</template>


<style scoped>
.wrapper {
  padding: 1rem;
  display: flex;
}

.filling {
  margin: auto;
  padding: 1rem;
  max-width: 32rem;
  width: 100%;
  border-radius: 1rem;
  background: #505461;
}

.caption {
  display: flex;
  border-radius: 0.625rem;
  background: #393C49;
  max-width: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 0.75rem 0 1.75rem;
  gap: 0.56rem;
}

.caption-content {
  padding-bottom: 1.25rem;
}

.title {
  color: #FFF;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.robot {
  margin-top: auto;
  width: 9.69144rem;
}

.text {
  margin-top: 0.375rem;
  color: #FFF;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

.info {
  margin-top: 2rem;
  border: 1px solid #b9c7d4;
  border-radius: 0.5rem;
  padding: 0.94rem;
  text-align: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: normal;
  text-align: center;
  padding: 0.9375rem;
  max-width: 100%;
  text-transform: uppercase;
  width: 100%;
  background: linear-gradient(180deg, #a22af0 0%, #606de4 100%);
}

.info__second {
  margin-top: 1rem;
}

a {
  color: #fff;
}

@media screen and (max-width: 850px) {
  .filling {
    max-width: 20rem;

  }

  .filling {
    padding: 0.75rem;
    border-radius: 0.75rem;
  }


  .title {
    font-size: 1.25rem;
  }

  .robot {
    margin-top: 0.5rem;
    width: 6rem;
  }

  .text {
    font-size: 0.875rem;
  }

  .info {
    margin-top: 1rem;
    font-size: 0.875rem;
    padding: 0.75rem;
  }

  .info__second {
    margin-top: 0.5rem;
  }
}
</style>